.History-item-title {
    color: #299441 !important;
    height: 30px;
}

.History-item-description {
    height: 30px;
}

.History-item-button-menu-item{
    background-color: #299441 !important;
}

.History-item-price{
    font-size: 16pt;
    text-align: right;
    font-weight: bold !important;
}

.History-item-grid-item{
    padding: 10px;
    min-height: 280px;
}