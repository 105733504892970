.scroll-area {
    height: calc(100% - 50px) !important;
    overflow: scroll;
    padding-bottom: 20px;
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
}

.Mui-selected{
    color: #299441 !important;
}

.MuiTabs-indicator {
    background-color: #299441 !important;
    height: 5px !important;
}

.Menu-scroll{
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 200px);
}