.order {
    background-color: #fff;
    border: 5px solid #299441;
    padding: 20px 10px;
}

.Order-btn-order {
    background-color: #299441 !important;
    color: #fff !important;
}

.Order-scroll {
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 390px);
}

@media (max-width: 820px) {
    .Order-scroll {
        flex: 1;
        overflow-y: scroll;
        height: calc(100vh - 480px);
    }
}