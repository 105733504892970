.Topping-item-grid-item{
    padding: 0;
    text-align: left;
}

.Topping-item-grid-item-count {
    font-size: 34px;
    color: #e0e0e0;
}

.Topping-item-grid-item-color {
    color: #299441;
}

.Topping-item-grid-item-title {
    font-size: 22px;
}