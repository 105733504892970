.Pay-modal .MuiPaper-root{
    max-width: 1000px !important;
    width: 1000px !important;
}

.Pay-modal-order-scroll{
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 300px);
}

.Pay-modal-customers-scroll{
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 400px);
}