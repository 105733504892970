.Menu-item-title {
    color: #299441 !important;
    height: 50px;
}

.Menu-item-description {
    height: 100px;
}

.Menu-item-button-menu-item{
    background-color: #299441 !important;
}

.Menu-item-price{
    font-size: 16pt;
    text-align: right;
    font-weight: bold !important;
}

.Menu-item-grid-item{
    padding: 10px;
}