.Order-item-grid-item{
    padding: 10px;
}

.Order-item-price{
    font-size: 14pt !important;
    font-weight: bold !important;
    text-align: right;
}

.Order-item-left-btn{

}

.Order-item-right-btn{
    text-align: right;
}