.custom-styles {
    --ReactInputVerificationCode-itemWidth: 7rem;
    --ReactInputVerificationCode-itemHeight: 8rem;
}

.custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #299441;
    font-weight: 500;
    font-size: 30pt;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: #ebebeb;
    transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
    background-color: #299441;
    height: 5px;
}

.btn-login {
    font-size: 24pt !important;
    background-color: #299441 !important;
}
