.offer-modal-sidebar {
    background-color: #fff;
    border: 5px solid #299441;
    padding: 20px 10px;
}

.offer-modal-sidebar-scroll {
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 500px);
}

@media (max-width: 820px) {
    .offer-modal-sidebar-scroll {
        flex: 1;
        overflow-y: scroll;
        height: calc(100vh - 520px);
    }
}