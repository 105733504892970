.Order-modal .MuiPaper-root{
    max-width: 100% !important;
    width: 100% !important;
}

.Order-modal-order-scroll{
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 300px);
}

.Order-modal-customers-scroll{
    flex: 1;
    overflow-y: scroll;
    height: calc(100vh - 400px);
}